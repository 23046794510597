<template>
    <div>
        <select-pos-model v-if="step === 1" v-model="idPos" :label="$t('labels.pos')" custom-class="mr-2"
            @change="onPosChange"></select-pos-model>
        <input-qr-scan-model v-else ref="inputQr" v-model="code" :label="label"
            @keyupEnter="inputScan"></input-qr-scan-model>

        <div v-if="step === 2" class="mt-5">
            <v-simple-table class="table-padding-2">
                <template v-slot:default>
                    <thead class="v-data-table-header">
                        <tr>
                            <th class="text-center">{{ $t('labels.basket') }}</th>
                            <th class="text-center">{{ $t('labels.area') }}</th>
                            <th class="text-center">{{ $t('labels.position') }}</th>
                            <th class="text-center">{{ $t('labels.uid') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in waitingItems" :key="`w_${index}`" class="text-center">
                            <td>{{ item.basket_code }}</td>
                            <td>{{ item.display_level }}</td>
                            <td>{{ item.locations }}</td>
                            <td>{{ item.uids }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <div v-else-if="step === 3" class="text-center">
            <v-alert color="error" text dark class="text-h4 mb-0">
                {{ $t('labels.basket') }}: {{ basket.code }}
            </v-alert>
            <v-alert color="green" text dark class="mb-0">
                {{ $t('labels.uid') }}: {{ basket.uids }}
            </v-alert>
        </div>
        <div v-else-if="step === 4" class="text-center">
            <v-alert color="error" text dark class="text-h4 mb-0">
                {{ $t('labels.basket') }}:{{ basket.code }}
            </v-alert>
            <v-alert color="green" text dark class="text-h6 mb-0">
                {{ $t('labels.uid') }}: {{ item.sku_uid }}
            </v-alert>
            <v-alert color="primary" text dark class="mb-0">
                {{ $t('labels.position') }}: {{ item.locations }}
            </v-alert>
        </div>
    </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
    name: "StowingDisplay",
    data: () => ({
        idPos: null,
        code: null,
        step: 1,
        basket: {},
        item: {},
        waitingItems: [],
    }),
    computed: {
        label() {
            switch (this.step) {
                case 2:
                    return this.$t('labels.basket_code')
                case 3:
                    return this.$t('labels.uid')
                case 4:
                    return this.$t('labels.location_code')
                default:
                    return 'Code'
            }
        }
    },
    mounted() {

    },
    methods: {
        getStowingWaiting() {
            httpClient.post('/get-stowing-display-waiting', { id_pos: this.idPos }).then(({ data }) => {
                this.waitingItems = [...data]
            }).catch()
        },
        onPosChange() {
            this.step = 2
            this.getStowingWaiting()
        },
        inputScan() {
            switch (this.step) {
                case 2:
                    return this.scanBasket()
                case 3:
                    return this.scanUid()
                case 4:
                    return this.scanPosition()
                default:
                    return 'Code'
            }
        },
        async scanBasket() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                const { data } = await httpClient.post('/get-basket-stowing-display', {
                    code: this.code,
                    id_pos: this.idPos
                })
                this.basket = { ...data }
                this.code = null
                this.isLoading = false
                this.step = 3
                this.$root.$emit('playSuccessAudio')
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
                this.code = null
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },
        async scanUid() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                const { data } = await httpClient.post('/get-stowing-display-item', {
                    code: this.code,
                    id_pos: this.idPos,
                    id_basket: this.basket.id,
                })
                this.code = null
                this.isLoading = false
                this.item = { ...data }
                this.step = 4
                this.$root.$emit('playSuccessAudio')
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
                this.code = null
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },
        async scanPosition() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                const { data } = await httpClient.post('/scan-location-stowing-display', {
                    code: this.item.sku_uid,
                    id_pos: this.idPos,
                    id_basket: this.basket.id,
                    basket_code: this.basket.code,
                    cell_id: this.code
                })
                this.code = null
                this.isLoading = false
                this.$root.$emit('playSuccessAudio')

                const emptyItem = {}
                this.item = { ...emptyItem }
                if (!data) {
                    this.step = 2
                    this.basket = { ...emptyItem }
                    this.getStowingWaiting()
                } else {
                    this.step = 3
                }
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
                this.code = null
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        }
    }
}
</script>

<style scoped></style>